import './App.css';
import { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '.';
import axios from "axios";
import DefaultLayout from './components/DefaultLayout.js';
import AdminDashboard from './screens/AdminDashboard.js';

const Account = lazy(() => import('./screens/Account.js'));
const Feedback = lazy(() => import('./screens/Feedback.js'));
const Help = lazy(() => import('./screens/Help.js'));
const Privacy = lazy(() => import('./screens/Privacy.js'));
const HomeNew = lazy(() => import('./screens/HomeNew.js'));
const ServicesNew = lazy(() => import('./screens/ServicesNew.js'));
const VoiceMessage = lazy(() => import('./screens/VoiceMessage.js'));
const ReferralTerms = lazy(() => import('./screens/ReferralTerms.js'));
const TandCs = lazy(() => import('./screens/TandCs.js'));
const Sales = lazy(() => import('./screens/Sales.js'));
const AboutUs = lazy(() => import('./screens/AboutUs.js'));
const Prices = lazy(() => import('./screens/Prices.js'));
const AuthPopUp = lazy(() => import('./components/auth/authPopUp.js'));
// const DefaultLayout = lazy(() => import('./components/DefaultLayout.js'));

function App() {

  const baseURL = process.env.REACT_APP_BACKEND_LIVE?process.env.REACT_APP_BASEURL_US:process.env.REACT_APP_LOCALURL_US
  const [loggedIn, setLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [goToSubscriptions, setGoToSubs] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const [priceClickCount, setPriceClickCount] = useState(0)
  const [routeChangeCount, setRouteChangeCount] = useState(0)
  const [email, setEmail] = useState(null);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {

        // setShowPopUp(false);
        setLoggedIn(true);
        getAccountDetail(user.uid);
      }
      else {

        setLoggedIn(false);
        setUserDetails(null);
      }
    })
    return () => unsubscribe();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const signInClicked = (goToSubscriptions, showRegister, email) => {
    if (email !== null && email !== undefined) {
      setEmail(email)
    }
    setShowPopUp(true)
    setGoToSubs(goToSubscriptions)
    setShowRegister(showRegister)
  }

  const pricingClicked = () => {

    if (window.location.pathname === '/') {

      setPriceClickCount(priceClickCount + 1)
      localStorage.setItem("scrollToPricing", false)
    }
    else {
      
      window.location = '/'
      localStorage.setItem("scrollToPricing", true)
      setRouteChangeCount(routeChangeCount + 1)
    }
  }

  useEffect(() => {
    let scrollToPricing = localStorage.getItem("scrollToPricing")
    if (window.location.pathname === '/' && scrollToPricing === "true") {
      setPriceClickCount(priceClickCount + 1)
      localStorage.setItem("scrollToPricing", false)
    }
  }, [routeChangeCount]);// eslint-disable-line react-hooks/exhaustive-deps

  const loggedInFunc = () => {
    setShowPopUp(false)
    if (goToSubscriptions) {
      window.location = '/services'
    }
    setGoToSubs(false)
  }

  const getAccountDetail = (uid) => {

    axios.get(baseURL + 'getAccountDetails', {
      params: {
        clientID: uid,
      }
    }).then((response) => {

      const data = response.data;
      if (data.found) {
        setUserDetails(data.user)
        localStorage.setItem("user", JSON.stringify(data.user))
      }
    });
  }

  const screenClicked = (e) => {
    let id = e.target.id
    if (id !== 'headerUserRow') {
      setClickCount(clickCount + 1)
    }
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
    <div className="App" onClick={e => screenClicked(e)}>
      <Router>
        <Routes>
          <Route path='/:username' element={<VoiceMessage />} />
          <Route path="/*" element={<DefaultLayout loggedIn={loggedIn} signInClicked={signInClicked} pricingClicked={pricingClicked} user={userDetails} clickCount={clickCount} />}>
            <Route index element={<HomeNew signInClicked={signInClicked} priceClickCount={priceClickCount} resetPriceClick={() => setPriceClickCount(0)} />} />
            <Route path='account' element={<Account user={userDetails} setUser={setUserDetails} />} />
            <Route path='prices' element={<Prices signInClicked={signInClicked} />} />
            <Route path='services' element={<ServicesNew />} />
            <Route path='servicesNew' element={<ServicesNew />} />
            <Route path='feedback' element={<Feedback />} />
            <Route path='about-us' element={<AboutUs />} />
            <Route path='help' element={<Help />} />
            <Route path='help/:faq' element={<Help />} />

            <Route path='privacy' element={<Privacy />} />
            <Route path='referralTerms' element={<ReferralTerms />} />
            <Route path='terms-and-conditions' element={<TandCs />} />
            <Route path='sales' element={<Sales userP={userDetails}/>} />
            <Route path='admin-dashboard' element={<AdminDashboard userP={userDetails}/>} />

          </Route>
        </Routes>
      </Router>
      {showPopUp && <AuthPopUp loggedIn={loggedInFunc} setOpen={setShowPopUp} setUser={setUserDetails} showRegister={showRegister} emailP={email} setEmailP={(x) => setEmail(x)} />}
    </div>
    </Suspense>
  );
}

// function DefaultLayout(props) {
//   return (
//     <>
//       <Header {...props} />
//       <Outlet />
//       {window.location.pathname!=='/sales'&&<Footer />}
//     </>
//   );
// }

export default App;

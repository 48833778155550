import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyC7adndmIMaxRzTlZUf9l8KIGg2IoHwFEU",
  authDomain: "vcml-7b6cd.firebaseapp.com",
  projectId: "vcml-7b6cd",
  storageBucket: "vcml-7b6cd.appspot.com",
  messagingSenderId: "345725459212",
  appId: "1:345725459212:web:d0a4caa23c8befc81c4729",
  measurementId: "G-4P7SBVXGBL"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {auth}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

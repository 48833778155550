import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
const Header = lazy(() => import('./Header.js'));
const Footer = lazy(() => import('./Footer.js'));

export default function DefaultLayout(props) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Header {...props} />
      <Outlet />
      {window.location.pathname!=='/sales'&&<Footer />}
    </Suspense>
  );
}
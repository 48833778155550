import '../styling/constants/buttons.css'
import '../styling/feedback.css'
import '../styling/constants/typography.css'

import React,{ useEffect, useState, Suspense } from 'react';
import { auth } from '..';
import axios from "axios";



export default function AdminDashboard({userP}) {


    const [user,setUser] = useState(userP)

    const [allowedAccess,setAllowedAccess] = useState(false)
    const [checkingAccess,setCheckingAccess] = useState(false)
    const [logData,setLogData] = useState(null)

   
    const baseURL = process.env.REACT_APP_BACKEND_LIVE?process.env.REACT_APP_BASEURL_US:process.env.REACT_APP_LOCALURL_US

    useEffect(() => {
        if(auth.currentUser)
        {
            let uid = auth.currentUser.uid
            checkUserPermissions(uid)
        }
        
    }, [auth.currentUser])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(userP){
            setUser(userP)
        }
    }, [userP])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    
        if(allowedAccess)
        {
            getBasicLogs()
        }

    }, [allowedAccess]);// eslint-disable-line react-hooks/exhaustive-deps


    const checkUserPermissions = (uid) => {

        setCheckingAccess(true)
        console.log("Checking access",uid)

        axios.get(baseURL+'checkSalesAccess',{
            params: {uid:uid}
        }).then( (response) => {
            console.log("Update Email",response.data)
            setCheckingAccess(false)
            setAllowedAccess(response.data.allowedAccess)
        })
    }


    const getBasicLogs = () => {


        axios.get(baseURL+'getBasicWidgetLogs',{
        }).then( (response) => {

            console.log("Basic Logs",response.data)
            let json_data = response.data

            var result = [];
            for(var i in json_data)
                result.push(json_data[i]);


            console.log("RESULT",result)
            setLogData(result)
        
        })
    }


    const showLogData = () => {

        let components = []

        logData.forEach(element => {
            
            components.push(<div className='column ai-start'>
                <span className='subtitle2bold alignLeft'>{element.domain}</span>
                <span>Contact Page Loads: {element.contactPageLoad.length}</span>
                <span>Message Sent: {element.messageSentVoice.length+element.messageSentText.length}</span>
                <span>Voice: {element.messageSentVoice.length}</span>
                <span>Text: {element.messageSentText.length}</span>
                <span>Widget Load: {element.widgetLoad.length}</span>
                <span>Widget Open: {element.widgetOpened.length}</span>
            </div>)

        });

        return components
    }



    

    return (
        <Suspense fallback={<div>Loading...</div>}>
        <div className="width100  column ai-centre padding24 gap24">

            
            {user && <div className='column'>
                <h2>User</h2>
                <div className='column ai-start'>
                    <span><strong>Name:</strong> {user.name}</span>
                    <span><strong>Email:</strong> {user.email}</span>
                </div>
            </div>}


            {allowedAccess && <div className="width100 height100 column ai-centre padding24 gap24">
                <h1>Usage</h1>

                {logData && <div className='grid gap24 ai-start'>
                    {showLogData()}
                </div>
                }
                
            </div>}




            {(!allowedAccess && !checkingAccess) && <div className='error subtitle2'>
                You do not have access.
            </div>}

            {(!allowedAccess && checkingAccess) && <div className='primary subtitle2'>
                Checking access...
            </div>}

        </div>
        </Suspense>
    );
}